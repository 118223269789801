<div class="terms-container">
  <header class="flex space-between">
    <h3>Terms of Service</h3>
    <em (click)="closePopUp()" class="bx bx-x"></em>
  </header>
  <section>
    <em>Last updated June 20, 2022</em>
    <br />
    <br />
    <p>
      Advertiser.CETVNow.com is a feature of CETV Now, LLC (“CETV”, “we”, “us”, “our”) that enables our customers
      (“you”, “your”) to create and launch advertisement campaigns to be displayed in commercial environments and to
      control the settings of advertisement insertions (each, a “Service”, and collectively, the “Services”), allowing
      you to reinforce your brand or product through targeted video and photo advertisements.
    </p>
    <br />
    <p>
      These Terms of Service (“TOS”) govern your access to and use of the CETV Ad Network and the CETV Ad Network
      website at
      <a href="https://www.cetvnow.com/" target="_blank">www.cetvnow.com</a>
      (the “Website”). By accessing or using the CETV Ad Network and Website, you hereby consent to and agree to these
      TOS and conclude a legally binding contract with CETV.
    </p>
    <br />
    <p>
      Please read these TOS carefully and make sure that you understand them. Unless otherwise stated, these TOS apply
      to the CETV Ad Network Services and Website and no other CETV products. You may not access or use the CETV Ad
      Network or Website if you are unwilling or unable to be bound by any part of these TOS. Separate TOS apply for
      other CETV products.
    </p>
    <br />
    <h3>CETV Ad Network</h3>
    <br />
    <strong>What We Offer You</strong>
    <br />
    <p>
      The CETV Ad Network, designed specifically for advertisers, allows you to create and launch advertisement
      campaigns and durations of and settings for your advertisement insertions, allowing you to reinforce your brand or
      product through targeted video or static image advertisements.
    </p>
    <br />
    <p>
      CETV Now strives to support a healthy digital advertising system that works for users, advertisers and publishers.
      Through the CETV Ad Network, you can:
    </p>
    <app-item marginLeft="15px">
      <p>
        • Promote your company brand or products across multiple locations and through multiple business sector
        categories throughout various national media markets.
      </p>
      <br />
      <p>• Create unique advertisement campaigns to fit your specific needs.</p>
      <br />
      <p>
        • Customize your advertisement campaign with features such as pre-selected geographic locations, selected
        budgets and campaign start and end dates.
      </p>
      <br />
      <p>
        • Design personalized advertisement schedules targeting your desired audiences, including neighboring
        businesses.
      </p>
      <br />
      <p>
        • Micro target your campaigns by promoting your advertisements to specific demographics based on your desired
        audiences.
      </p>
      <br />
      <p>• Monitor your advertisements and promote your products and services in real time as customers are buying.</p>
    </app-item>
    <br />
    <strong>Your Acknowledgements and Responsibilities of Using the CETV Ad Network Services</strong>
    <br />
    <br />
    <p>By advertising through the CETV Ad Network, you understand these TOS and agree to be bound by them.</p>
    <br />
    <p>
      Use of the CETV Ad Network requires MP4 files for video upload and JPEG or PNG files for static image uploads.
      Certain file size and video length restrictions apply.
    </p>
    <br />
    <p>
      Use of the CETV Ad Network requires MP4 files for video upload and JPEG or PNG files for static image uploads.
      Certain file size and video length restrictions apply.
    </p>
    <br />
    <p>
      You understand and consent that CETV may, at its sole discretion, deem any advertisement through the CETV Ad
      Network in violation of these TOS, including but not limited to improper file size and or video length for your
      advertisement. If a violation is found, the advertisement may be terminated by CETV.
    </p>
    <br />
    <p>
      You understand that advertisements remain subject to review and re-review at all times by CETV. It is your
      responsibility to understand and comply with these TOS.
    </p>
    <br />
    <h3>Intellectual Property</h3>
    <br />
    <p>
      CETV Now is committed to helping people and organizations protect their intellectual property rights. These TOS
      prohibit posting content through the CETV Ad Network or Website that violates someone else’s intellectual property
      rights, including but not limited to copyright, trademark and patent rights.
    </p>
    <br />
    <p>
      You should only upload content that you have made or that you have permission to use. That means that you may not
      upload any content for advertisements or promotions used through the CETV Ad Network or Website that you did not
      create or that someone else owns the copyright to.
    </p>
    <br />
    <p>
      By uploading your intellectual property through the CETV Ad Network or Website, you hereby grant CETV a
      nonexclusive, royalty free, worldwide and perpetual license to display your intellectual property through our
      Services for the campaign period defined by you with respect to the uploaded content. Further, by uploading your
      intellectual property through the CETV Ad Network or Website, you hereby grant CETV a nonexclusive, royalty free,
      worldwide and perpetual license to modify and/or transcode your intellectual property to the extent needed to
      properly format for our Services. Notwithstanding the immediately preceding sentence, any modifications or
      transcoding to your intellectual property by CETV are exclusively for the period defined by your campaign with
      respect to the uploaded content.
    </p>
    <br />
    <h3>Subscription Terms and Refunds</h3>
    <br />
    <p>
      To use the CETV Services on the CETV Ad Network, you must first create an account through our Website and build
      your campaign, which includes a defined start date and end date for your campaign, a budget for your campaign,
      business industries that your campaign will target and the geographic location or locations in which your campaign
      will run. Payment is based exclusively on the campaign or campaigns that you create.
    </p>
    <br />
    <p>
      Your campaign budget can be set either 1) per day or 2) per campaign, set by you at the time you create your
      campaign on the Website, with payment due at checkout at the time the campaign is created. Any user may run
      multiple campaigns concurrently.
    </p>
    <br />
    <p>
      You authorize CETV to run the credit card associated with your account for your campaign payment pursuant to the
      campaign budget option selected.
    </p>
    <br />
    <p>
      No refunds are available for any CETV Service on the CETV Ad Network. Campaigns on the CETV Ad Network may be
      paused at any time while the campaign is still running. Any credit remaining from a paused campaign may be used on
      the initial campaign that the credit was designated for by restarting the campaign at a later time. To apply any
      credit remaining from a paused campaign to a separate campaign, please contact
      <a href="support@cetvnow.com">support@cetvnow.com</a>
      .
    </p>
    <br />
    <h3>Use of Passwords</h3>
    <br />
    <p>
      Access to certain portions of our Website (for example, your account), may involve the use of a unique user name
      and/or password. You are solely responsible for maintaining the security of such user name(s) and password(s), and
      will promptly notify us if you believe such security has been compromised. You will not use the user name(s) or
      password(s) of any third party without such party’s express prior consent.
    </p>
    <br />
    <h3>Prohibited Uses</h3>
    <br />
    <p>You may not use the CETV Ad Network or Website to transmit any material that:</p>
    <app-item marginLeft="15px">
      <p>
        • Contains any obscene material or images of nudity, or describes, depicts or represents explicit sexual
        activities.
      </p>
      <br />
      <p>
        • Discriminates or encourages discrimination against people based on personal and protected characteristics or
        attributes including, but not limited to race, ethnicity, color, national origin, religion, age, sex, sexual
        orientation, gender identity and disability.
      </p>
      <br />
      <p>
        • Promotes or encourages the use of illegal drugs, tobacco products, tobacco-related products, and products that
        simulate smoking or are modeled on tobacco products, including but not limited to cigarettes, cigars, vape and
        smokeless tobacco.
      </p>
      <br />
      <p>• Constitutes, facilitates or promotes any other illegal products, services or activities.</p>
      <br />
      <p>• Promotes or encourages the use of firearms.</p>
      <br />
      <p>
        • Infringes on the intellectual property rights of any third party, including but not limited to trademarks or
        copyrights of another.
      </p>
    </app-item>
    <br />
    <h3>Indemnity</h3>
    <br />
    <p>
      You agree to indemnify, defend, and hold harmless CETV , its members, officers, employees, agents,
      representatives, suppliers, and content and service providers from and against any and all losses, expenses,
      damages, and costs, including reasonable attorneys fees, resulting from any violation of these TOS or any activity
      related to your use of the CETV Ad Network or Website by you or any other person accessing the CETV Ad Network or
      Website.
    </p>
    <br />
    <h3>Your Information</h3>
    <br />
    <p>
      Our Privacy Policy explains how we treat your personal data and protect your privacy when you use the CETV Ad
      Network and Website.
    </p>
    <br />
    <h3>Applicable Law</h3>
    <br />
    <p>
      Your use of the CETV Ad Network and Website shall be governed by the laws of the State of Arizona without regard
      to its choice of law principles. You agree to submit to the personal jurisdiction of the state and federal courts
      located in Maricopa County, Arizona with respect to any legal proceedings arising out of these TOS or your use of
      the CETV Ad Network and Website.
    </p>
    <br />
    <h3>Age of Consent</h3>
    <br />
    <p>
      By using the CETV Ad Network and Website, you represent that you are at least the age of majority in your state of
      residence, or that you are the age of majority in your state of residence and you have given us your consent to
      allow any minors within your direct control to use the CETV Ad Network and Website.
    </p>
    <br />
    <h3>Changes to these Terms of Service</h3>
    <br />
    <p>
      These TOS were posted on the date set forth above. We reserve the right, in our discretion and without notice, to
      modify these TOS at any time. You are advised to periodically review these TOS for any changes. Any such changes
      will take effect immediately upon its posting on the Website. Your use of the CETV Ad Network and Website
      following any such change constitutes your agreement to follow and be bound by the TOS, as amended.
    </p>
    <br />
    <h3>Changes to the CETV Ad Network</h3>
    <br />
    <p>
      CETV reserves the right to make additions, deletions or modifications to the CETV Ad Network and Website, in our
      discretion and without notice at any time.
    </p>
    <br />
    <h3>Contact Us</h3>
    <br />
    <p>
      If you have any questions about these TOS, please contact us. We will be happy to answer any questions or concerns
      you might have.
    </p>
    <br />

    <p>
      CETV Now, LLC
      <br />
      3219 E. Camelback Road, Suite 315
      <br />
      Suite 315
      <br />
      Phoenix, Arizona 85108
      <br />
      (480) 941-3703
      <br />
      <a>legal@cetvnow.com</a>
    </p>

    <app-item *ngIf="showCheckbox">
      <div class="checkbox-container">
        <app-checkbox [(ngModel)]="termsAccepted" (valueChange)="closePopUp()" [disable]="termsAccepted"></app-checkbox>
        <p>
          By checking this box I agree to these Terms of Service. I am confirming that I have carefully read these Terms
          of Service and I understand them.
        </p>
      </div>
    </app-item>
  </section>
</div>
