// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  BASE_URL: 'https://telemated-ads-api-pro.herokuapp.com',
  HOST_BASE_URL: 'https://telemated-api-pro.herokuapp.com/',
  STRIPE_PK:
    'pk_live_51Jz6PqKepQDGTleEWbt14uFfVDJNQOIw9wUfEtliXyHxxoCUZOjJUbHGEGxlr83NbD7Ck6A4HL8sStEL80FIebxy00rJJwirAC',
  TELEMATED_URL: 'https://us-east-1.aws.data.mongodb-api.com/app/telemated-integrations-zther',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
