import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UiService } from 'src/app/shared/services/ui.service';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  //**Open an close the mobile menu */
  showMobileItems = false;

  //**Variables to change the menu depending on the route */
  urlsMain = [
    '/dashboard/build-campaign', '/dashboard/campaigns',
    '/dashboard/campaign-detail', '/account', '/locations/details'
  ];
  urlsAuth = ['/sign-up', '/forgot'];

  //**Principal routes */
  routes: any[] = [
    {
      url: '/dashboard/build-campaign',
      name: 'BUILD CAMPAIGN',
      associatedUrls: ['/dashboard/campaign-detail'],
    },
    {
      url: '/locations/details',
      name: 'LOCATION DETAILS',
    },
    {
      url: '/login',
      name: 'LOGOUT',
    },
  ];

  /**Open an close principal routes when the navbar is mobile */
  showPrincipalMobileRoutes = true;

  /**
   *
   * @param router to manage the routing of the app
   * @param uiService to manage the modals
   */
  constructor(
    public router: Router,
    public uiService: UiService,
    private authService: AuthService
    ) {}

  /**
   * Method to navigate when the page is mobile
   * @param route to know which page go
   */
  navigateMobile(route: any) {
    if (route.url === '/login') {
      this.authService.logout();
    }

    if (route.subRouteInfo) {
      //open subroutes in mobiles
      route.subRouteInfo.openMobile = route.subRouteInfo.openMobile ? false : true;
      this.showPrincipalMobileRoutes = false;
    } else {
      this.router.navigateByUrl(route.url);
      this.showMobileItems = false;
    }
  }

  /**
   * Method to close subroutes in mobile
   */
  closeSubRoutes() {
    this.showPrincipalMobileRoutes = true;
    this.routes.forEach((route) => {
      if (route.subRouteInfo) {
        route.subRouteInfo.openMobile = false;
      }
    });
  }

  /**
   * Method to navigate in deskptop screent
   * @param route object to know which page go
   * @param eventSubroute to know if comes from a subroute
   */
  navigate(route: any, eventSubroute?: any) {
    if (route.url === '/login') {
      this.authService.logout();
      return;
    }

    this.router.navigateByUrl(route.url);
    if (eventSubroute) {
      eventSubroute.stopPropagation();
    }
  }
  /**
   * Method to open and close subroutes in desktop screen
   * @param route object to know which page go
   * @param action could be open or close
   */
  toggleSubRoute(route: any, action: 'open' | 'close') {
    if (route.subRouteInfo) {
      route.subRouteInfo.openDesktop = action === 'open' ? true : false;
    }
  }

  calculateDecoratorWidth(route: any) {
    switch (route.name) {
      case 'BUILD CAMPAIGN':
        return '160';
      case 'LOCATION DETAILS':
        return '172';
      default:
        return '160';
    }
  }
}
